// Import global css file
import './scss/app.scss';

/** ------------------------------*/
// GLOBAL VARIABLES
/** ------------------------------*/
//const navItems = Array.prototype.slice.call(document.querySelectorAll('.item-has-child'));


// mobile menu toggle and calculate top position of menu

function menuToggle() {
  const menuOpen = document.querySelector('.site-header__burger');
  const menuClose = document.querySelector('.menu-close');
  const mainMenu = document.querySelector('.main-nav');

  menuOpen.addEventListener('click', () => {

    if (!mainMenu.classList.contains('active')) {
      mainMenu.classList.add('active');
      document.body.classList.add("open");
    }
  });
  if (menuClose) {
    menuClose.addEventListener('click', () => {

      if (mainMenu.classList.contains('active')) {
        mainMenu.classList.remove('active');
        document.body.classList.remove("open");
      }
    });

    // if (window.outerWidth > 769) {
    //   navItems.forEach((navitem) => {
    //     const ChildChevron = navitem.querySelector('.child-chevron');

    //     ChildChevron.addEventListener('click', () => {
    //       navitem.classList.toggle('hover');
    //     });
    //   })
    // }
  }
  if (window.outerWidth < 769) {
    mobileNav()
  }
}

menuToggle();

function mobileNav() {
  let itemHasChild = document.querySelectorAll(".item-has-child");
  for (let i = 0; i < itemHasChild.length; i++) {
    itemHasChild[i].firstElementChild.addEventListener("click", function(e){
      e.preventDefault();
      if (this.parentNode.classList.contains("active")) {
        this.parentNode.classList.remove("active");
      }
      else {
        this.parentNode.classList.add("active");
      }
    });
  }
}

// sub menu toggle logic

// var mobileNavLogic = function (array,index) {
//   array[index].children[1].addEventListener('click', function () {
//       navItems.forEach(function(navItem,it) {
//         var itemChild = navItem.children[2];

//           if(it === index) {
//               if(!navItem.classList.contains('active')) {
//                 navItem.classList.add('active');
//                 if(itemChild) {
//                   itemChild.classList.add('active');
//                 }
//             }
//             else {
//                 navItem.classList.remove('active');
//                 if(itemChild) {
//                   itemChild.classList.remove('active');
//                 }
//             }
//           }
//       });
//   });
// }

// chevron size logic for mobile
// function navItemWithChildren() {

//   if(navItems) {
//     navItems.forEach(function (navItem){
//       const itemWidth = navItem.offsetWidth;
//       const anchorWidth = navItem.children[0].offsetWidth;
//       const spanTag = navItem.children[1];
//       const navItemSecondChild = navItem.querySelector('.child-menu .item-has-child');

//       spanTag.style.width = itemWidth -  anchorWidth - 100 + 'px';

//       if(navItemSecondChild) {
//         const secondChildSpan = navItemSecondChild.children[1];
//         secondChildSpan.style.width = itemWidth -  anchorWidth - 100 + 'px';
//       }
//     });
//   }
// }

// if (window.outerWidth < 769) {
//   navItemWithChildren();

//   for(var i = 0, len = navItems.length; i < len; i++) {
//     mobileNavLogic(navItems,i);
//   }
// }

function scrolldownClick() {
  let clickedElement = document.querySelectorAll(".hero__scrollDown");
  if (clickedElement) {
    const headerHeight = document.querySelectorAll('.site-header')[0].clientHeight;
    const windowHeight = window.innerHeight;
    const scrollHeight = windowHeight - headerHeight;
    for (let i = 0; i < clickedElement.length; i++) {
      clickedElement[i].addEventListener('click', () => window.scrollTo({
        top: scrollHeight,
        behavior: 'smooth',
      }));
    }
  }
}

scrolldownClick()

function accordianFunction() {
  let textDropdownHeading = document.querySelectorAll(".text__dropdown--heading");
  for (let i = 0; i < textDropdownHeading.length; i++) {
    textDropdownHeading[i].addEventListener("click", function(){
      if (this.parentElement.classList.contains('active')) {
        this.parentElement.classList.remove('active');
      }
      else {
        if (document.querySelectorAll('.text__dropdown.active').length) {
          document.querySelectorAll('.text__dropdown.active')[0].classList.remove('active');
        }
        this.parentElement.classList.add('active');
      }
    });
  }
}

accordianFunction();

function popupLogic() {
  if (document.querySelectorAll('.popupButton')) {
    const popupButtonEl = document.querySelectorAll('.popupButton');
    const closeBTN = document.querySelectorAll('.popup-close');
    for (let i = 0; i < popupButtonEl.length; i++) {
      popupButtonEl[i].addEventListener("click", (e) => {
        e.preventDefault();
        let clickedEl = popupButtonEl[i].dataset.id;
        let popupID = document.getElementById(clickedEl);
        popupID.classList.add("active");
        document.body.classList.add("open");
        // Initiate slider once popup is closed
        let popupCarousel = document.querySelectorAll(".popup.active .popup-content-gallery");
        if (popupCarousel.length) {
          let glide = new Glide('.active .popup-content-gallery', {
            autoplay: 3000,
            hoverpause: true,
            perView: 4,
            gap: 0,
            //bound: true,
            breakpoints: {
              1200: {
                perView: 3,
              },
              800: {
                perView: 3,
              },
              500: {
                perView: 1.5,
              },
            },
          });
          glide.mount();
          // Destroy slider once popup is closed
          document.querySelectorAll(".popup.active .popup-close")[0].addEventListener("click", function(){
            glide.destroy();
          });
        }
      });
      for (let a = 0; a < closeBTN.length; a++) {
        closeBTN[a].addEventListener("click", function(){
          this.parentNode.parentNode.classList.remove("active");
          document.body.classList.remove("open");
        });
      }
    }
  }
}

popupLogic();

function injectVideoScript(src, cb) {
  // don't inject script twice - check if exists
  if (document.getElementById('vimeo-video-script')) {
    return false;
  }
  // create script tag
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'vimeo-video-script';
  script.async = true;
  script.defer = true;
  script.dataset.otIgnore = true;
  // set cb states
  script.onload = function () {
    return cb(true);
  };
  script.onerror = function (e) {
    return cb(false, e);
  };
  script.src = src;
  document.getElementsByTagName('head')[0].appendChild(script);
}

function videoBlockPlay() {
  iframeContentBlockStyling();
  let playButton = Array.prototype.slice.call(document.querySelectorAll(".video__play"));
  injectVideoScript('https://player.vimeo.com/api/player.js', function () {
    if (playButton.length > 0) {
      for (let i = 0; i < playButton.length; i++) {
        playButton[i].addEventListener("click", () => {
          // Remove active from other elements
          if (Array.prototype.slice.call(document.querySelectorAll(".video__overlay.active")).length) {
            let activeVideos = Array.prototype.slice.call(document.querySelectorAll(".video__overlay.active"));
            for (let a = 0; a < activeVideos.length; a++) {
              activeVideos[a].classList.remove('active');
              let iframeElement = activeVideos[a].nextElementSibling;
              /* eslint-disable */
              let vimeo_options = {
                controls: true
              }
              let player = new Vimeo.Player(iframeElement, vimeo_options);
              player.unload();
              /* eslint-enable */
            }
          }
          playButton[i].parentNode.classList.add("active");
          let iframeElement = playButton[i].parentNode.nextElementSibling;
          /* eslint-disable */
          let vimeo_options = {
            controls: true
          }
          let player = new Vimeo.Player(iframeElement, vimeo_options);
          player.play();
          player.on('pause', function(){
            playButton[i].parentNode.classList.remove("active");
          });
          /* eslint-enable */
          // Close and pause video on scroll
          window.addEventListener('scroll', () => {
            if (Array.prototype.slice.call(document.querySelectorAll(".video__overlay.active").length)) {
              let activePlayers = Array.prototype.slice.call(document.querySelectorAll(".video__overlay.active"));
              for (let a = 0; a < activePlayers.length; a++) {
                let iframeElement = activePlayers[a].nextElementSibling;
                /* eslint-disable */
                let vimeo_options = {
                  controls: false
                }
                let player = new Vimeo.Player(iframeElement, vimeo_options);
                player.pause();
                activePlayers[a].classList.remove("active");
                /* eslint-enable */
              }
            }
          });
        });
      }
    }
  })
}

videoBlockPlay();

function formValidation() {
  const formElement = document.querySelectorAll('.contact-form button');
  // const form = document.querySelectorAll(".contact-form")[0];
  if (formElement) {
    for (let i = 0; i < formElement.length; i++) {
      formElement[i].addEventListener("click", (e) => {
        e.preventDefault();
        let inputFields = Array.prototype.slice.call(document.querySelectorAll('.contact-form input'));
        for (let a = 0; a < inputFields.length; a++) {
          if (inputFields[a].required) {
            if (inputFields[a].name === 'emailAddress') {
              let emailField = inputFields[a].value;
              if (emailField.length < 1) {
                inputFields[a].parentElement.classList.add("error");
              }
              else {
                let regEx = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
                let validEmail = regEx.test(emailField);
                if (!validEmail) {
                  inputFields[a].parentElement.classList.add("error");
                }
                else {
                  inputFields[a].parentElement.classList.remove("error");
                }
              }
            }
            else if (inputFields[a].getAttribute('type') === 'checkbox') {
              if (inputFields[a].checked) {
                inputFields[a].parentElement.classList.remove("error");
              }
              else {
                inputFields[a].parentElement.classList.add("error");
              }
            }
            else {
              if (inputFields[a].value < 1) {
                inputFields[a].parentElement.classList.add("error");
              }
              else {
                inputFields[a].parentElement.classList.remove("error");
              }
            }
          }
        }
        let enquiry = document.getElementById("enquiry");
        if (enquiry.value.length < 5) {
          enquiry.parentElement.classList.add("error");
        }
        else {
          enquiry.parentElement.classList.remove("error");
        }
        let errorFields = Array.prototype.slice.call(document.querySelectorAll('.error'));
        if (errorFields.length > 0) {
          e.preventDefault();
          window.formHasErrors = true;
        }
        else {
          e.preventDefault();
          window.formHasErrors = false;
        }
      });
    }
  }
}

formValidation();

function iframeContentBlockStyling() {
  const iframeElements = document.querySelectorAll('.contentBlock__fullWidth iframe');
  if (iframeElements) {
    for (let i = 0; i < iframeElements.length; i++) {
      iframeElements[i].parentNode.classList.add("content__video");
      const el = document.createElement("div");
      el.classList.add("video__overlay");
      iframeElements[i].parentNode.prepend(el);
      iframeElements[i].previousSibling.innerHTML = '<div class="video__play"><img src="/themes/tommeetippee/assets/img/play.svg" width = "42" height = "76" alt = "" ></div>';
    }
  }
}

import Glide from '@glidejs/glide';
function imageCarousel() {
  let imageCarousel = document.querySelectorAll('.glide');
  if (imageCarousel.length) {
    let glide = new Glide('.glide', {
      autoplay: 3000,
      hoverpause: true,
      perView: 2,
      gap: -2,
      bound: true,
    });

    glide.mount();
  }
}
imageCarousel();


